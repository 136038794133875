import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import JncBalanceCard from '../global/JncBalanceCard'
import JncSectionTitle from '../global/JncSectionTitle'
import BalanceFiatDeposit from '../../tables/balances/actions/fiat/BalanceFiatDeposit'
import BalanceFiatWithdraw from '../../tables/balances/actions/fiat/BalanceFiatWithdraw'
import BalanceFiatPaymentOut from '../../tables/balances/actions/fiat/BalanceFiatPaymentOut'
import BalanceFiatFx from '../../tables/balances/actions/fiat/BalanceFiatFx'
import BalanceFiatTransfer from '../../tables/balances/actions/fiat/BalanceFiatTransfer'
import ManageCurrenciesModal from '../modals/ManageCurrenciesModal'
import Icon from '../../icons/Icon'
import { defaultCurrenciesToDisplay, typeItems, useJunoCommonFunctions } from '../../../helpers'
import SidebarCurrencyModal from '../modals/SidebarCurrencyModal'
import ManageCurrenciesDrawer from '../modals/ManageCurrenciesDrawer'

export default function JncBalances({ isDashboardPage, isBalancesTab }) {
    const [activeData, setActiveData] = useState({})
    const [activeModal, setActiveModal] = useState('')
    const [manageModalState, setManageModalState] = useState(false)
    const [manageDrawerState, setManageDrawerState] = useState(false)
    const [selectedCurrencies, setSelectedCurrencies] = useState(
        defaultCurrenciesToDisplay
    )
    const { useWindowWidth, getClientFeeSetting, getBalances, getManagedCurrencies } = useJunoCommonFunctions()
    const { balancesCurrencies: balancesData, clientData, managedCurrencies } = useSelector((state) => state.juno)
    const [balancesDataDashboard, setBalancesDataDashboard] = useState([])
    const windowWidth = useWindowWidth()

    const [currencyModal, setCurrencyModal] = useState(false)

    useEffect(() => {
        fetchFeeSetting()
    }, [activeModal])

    const fetchFeeSetting = async () => {
        const transactionType = typeItems.find(
            (item) => item.label === activeModal
        )?.value

        const payload = {
            clientId: clientData?.clientId,
            transactionType: transactionType,
            currencyType: 'fiat',
        }

        transactionType && (await getClientFeeSetting(payload))
    }

    function onCloseCurrencyModal() {
        setCurrencyModal(false)
    }
    async function openBalanceMobile(data) {
        setActiveData(data)
        setCurrencyModal(true)
    }

    function onCloseManageModal() {
        setManageModalState(false)
        setManageDrawerState(false)
        setSelectedCurrencies(managedCurrencies ? managedCurrencies : [])
    }

    useEffect(() => {
        if (balancesData) {
            const data = balancesData.filter((x) => {
                return selectedCurrencies.includes(x.currencyShortName)
            })
            setBalancesDataDashboard(data)
        }
    }, [balancesData, selectedCurrencies])

    useEffect(() => {
        if (managedCurrencies?.length) {
            setSelectedCurrencies(managedCurrencies)
        }
    }, [managedCurrencies])

    useEffect(() => {
        clientData?.clientId && getManagedCurrencies(clientData?.clientId)
    }, [clientData])

    useEffect(() => {
        if (windowWidth > 991 && currencyModal) {
            setCurrencyModal(false)
        }
    }, [windowWidth])

    const handleManageCurrencies = () => {
        if (windowWidth <= 767) {
            setManageDrawerState(true)
        } else {
            setManageModalState(true)
        }
    }

    return (
        <section
            className={`jncSection jncBalances mb mt ${!isBalancesTab ? 'hidden' : ''} ${isDashboardPage ? 'is-dashboard' : ''}`}
        >
            {isDashboardPage && (
                <div className="flex justify-between max-991 items-center mb-2">
                    <JncSectionTitle title="Balances" />
                    <span onClick={handleManageCurrencies}>
                        <Icon id="manageCurrencies" className="w-6" />
                    </span>
                </div>
            )}
            <div className="jncCardsGrid">
                {isDashboardPage &&
                    windowWidth > 991 &&
                    balancesDataDashboard.map((x) => {
                        return (
                            <JncBalanceCard
                                key={x.currencyShortName}
                                data={x}
                                setActiveModal={setActiveModal}
                                setActiveData={setActiveData}
                            />
                        )
                    })}
                {!isDashboardPage &&
                    windowWidth > 991 &&
                    balancesData.map((x) => {
                        return (
                            <JncBalanceCard
                                key={x.currencyShortName}
                                data={x}
                                setActiveModal={setActiveModal}
                                setActiveData={setActiveData}
                            />
                        )
                    })}
                {!isDashboardPage &&
                    windowWidth < 992 &&
                    balancesData.map((x) => {
                        return (
                            <div
                                key={x.currencyShortName}
                                onClick={() => openBalanceMobile(x)}
                            >
                                <JncBalanceCard
                                    data={x}
                                    setActiveModal={setActiveModal}
                                    setActiveData={setActiveData}
                                />
                            </div>
                        )
                    })}
                {isDashboardPage &&
                    windowWidth < 992 &&
                    balancesDataDashboard.map((x) => {
                        return (
                            <div
                                key={x.currencyShortName}
                                onClick={() => openBalanceMobile(x)}
                            >
                                <JncBalanceCard
                                    data={x}
                                    setActiveModal={setActiveModal}
                                    setActiveData={setActiveData}
                                />
                            </div>
                        )
                    })}
                {isDashboardPage && windowWidth > 991 && (
                    <button
                        onClick={handleManageCurrencies}
                        className="jncAddItem no-mt manage-currencies"
                    >
                        <Icon id="manageCurrencies" />
                        <p data-e2e="manage-currencies">Manage currencies</p>
                    </button>
                )}
            </div>
            {isDashboardPage && (
                <ManageCurrenciesModal
                    balancesData={balancesData}
                    open={manageModalState}
                    onClose={onCloseManageModal}
                />
            )}
            {isDashboardPage && (
                <ManageCurrenciesDrawer
                    balancesData={balancesData}
                    open={manageDrawerState}
                    onClose={onCloseManageModal}
                />
            )}
            <BalanceFiatDeposit
                open={activeModal === 'deposit'}
                onClose={() => setActiveModal('')}
                row={activeData}
            />
            <BalanceFiatWithdraw
                open={activeModal === 'withdraw'}
                onClose={() => setActiveModal('')}
                row={activeData}
            />
            <BalanceFiatPaymentOut
                open={activeModal === 'paymentOut'}
                onClose={() => setActiveModal('')}
                row={activeData}
            />
            <BalanceFiatFx
                open={activeModal === 'fx'}
                onClose={() => setActiveModal('')}
                row={activeData}
            />
            <BalanceFiatTransfer
                open={activeModal === 'transfer'}
                onClose={() => setActiveModal('')}
                row={activeData}
            />
            <SidebarCurrencyModal
                open={currencyModal}
                data={activeData}
                onClose={onCloseCurrencyModal}
                setModalAction={setActiveModal}
            />
        </section>
    )
}
